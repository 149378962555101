import animateScrollTo from "animated-scroll-to";

class ScrollTo {
  private buttonNodes: NodeListOf<HTMLElement>;

  constructor(selector: string, public top = false) {
    this.buttonNodes = document.querySelectorAll(selector);

    if (this.buttonNodes) {
      this.init();
    }
  }

  init(): void {
    this.buttonNodes.forEach((node: HTMLElement): void =>
      node.addEventListener("click", this.clickDispatcher.bind(this))
    );
  }

  clickDispatcher(e: Event): void {
    e.preventDefault();
    const to: string = (e.target as HTMLElement).dataset.scrollToId;

    if (this.top) {
      animateScrollTo(0, { speed: 300 });
      return;
    }

    if (to) {
      const toElement: HTMLElement = document.getElementById(to);
      const offset: number = window.innerHeight * 0;

      if (toElement) {
        const top: number = toElement.offsetTop - offset;
        animateScrollTo(top, { speed: 800 });
      }
    }
  }
}

export default ScrollTo;
