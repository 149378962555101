/* global FormData  */
import * as qs from "qs";
import axios, { AxiosResponse } from "axios";
import { IObject, FormData } from "../Interfaces";

class ApiSync {
  private lang: string;

  constructor(public url: string) {
    this.lang = document.body.dataset.lang || document.documentElement.lang;
  }

  public fetch<T>(params?: IObject): Promise<T> {
    return axios({
      url: this.url,
      method: "get",
      params: {
        lang: this.lang,
        ...params
      }
    }).then((response: AxiosResponse<T>) => response.data);
  }

  public save<T, K>(data: FormData): Promise<T> {
    const formData = new FormData();
    const keys = Object.keys(data);

    keys.forEach(key => {
      const value = data[key] as string;
      if (typeof value === "undefined" || value === "" || value.length === 0)
        return;
      formData.append(key, value);
    });

    try {
      return axios({
        url: this.url,
        method: "post",
        params: {
          lang: this.lang
        },
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }).then((response: AxiosResponse<T>) => response.data);
    } catch (error) {
      return error;
    }
  }

  public delete<T, K>(data: K): Promise<T> {
    return axios({
      url: this.url,
      method: "delete",
      params: {
        lang: this.lang
      },
      data: qs.stringify({
        ...data
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }).then((response: AxiosResponse<T>) => response.data);
  }
}

export default ApiSync;
