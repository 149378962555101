import Api from "../api/ApiSync";
import { APIResponseStatus, IObject } from "../Interfaces";

class Popup {
  public el: HTMLElement;
  public agreeBtn: NodeListOf<HTMLElement>;
  private popupID = "";
  private api: Api;

  constructor(selector: string | HTMLElement) {
    if (typeof selector === "string") {
      this.el = document.querySelector(selector);
    } else {
      this.el = selector;
    }

    if (this.el) {
      this.agreeBtn = this.el.querySelectorAll(".js-set-cookie");
      this.init();
    }
  }

  private init(): void {
    this.initApi();
    this.agreeBtn.forEach(btn => {
      btn.addEventListener("click", this.onAgreeClick.bind(this));
    });
  }

  private initApi(): void {
    const { requestUrl, popupId } = this.el.dataset;
    this.api = new Api(requestUrl);
    this.popupID = popupId;
  }

  private closePopup(e?: Event): void {
    if (e) e.preventDefault();
    this.el.classList.add("hide");
  }

  private onAgreeClick(e?: Event): void {
    let val = "0";
    if (e) {
      e.preventDefault();
      const target = e.target as HTMLElement;
      val = target.dataset.cookieValue;
    }
    this.setApiCookie({ cookie: this.popupID, value: val });
    this.closePopup();
  }

  private setApiCookie(cookie: IObject): Promise<APIResponseStatus> {
    const response = this.api.save<APIResponseStatus, IObject>(cookie);
    return response;
  }
}

export default Popup;
