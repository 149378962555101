import { toggle } from "slidetoggle";

class ShowMore {
  private containerEL: HTMLElement;
  private showAll = false;
  private contentEl: HTMLElement;

  constructor(container: string | HTMLElement) {
    if (typeof container === "string") {
      this.containerEL = document.querySelector(container);
    } else {
      this.containerEL = container;
    }

    if (this.containerEL) {
      this.init();
    }
  }

  private init(): void {
    this.contentEl = this.containerEL.querySelector("[data-show-more-content]");
    this.initTriggerButton();
  }

  private updateStatus(): void {
    this.containerEL.dataset.showMore = this.showAll ? "1" : "0";
  }

  private toggleHiddenEls(): void {
    toggle(this.contentEl, { miliseconds: 600, elementDisplayStyle: "flex" });
    this.showAll = !this.showAll;
    this.updateStatus();
  }

  private initTriggerButton(): void {
    const button: HTMLElement = this.containerEL.querySelector(
      "[data-show-more-button]"
    );

    if (button) {
      button.addEventListener("click", this.toggleHiddenEls.bind(this));
    }
  }
}

export default ShowMore;
