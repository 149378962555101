import Controler from "../base/Controler";
import SimpleModal from "./SimpleModal";

class ModalControler extends Controler {
  constructor() {
    super();
    this.initSimpleModal();
    this.initSimpleModalWithoutHash();
  }

  initSimpleModal(): SimpleModal[] {
    const modalsArr: SimpleModal[] = [];

    this.selectElements(".js-simple-modal").forEach(modal => {
      modalsArr.push(new SimpleModal(modal));
    });

    return modalsArr;
  }

  initSimpleModalWithoutHash(): SimpleModal[] {
    const modalsArr: SimpleModal[] = [];

    this.selectElements(".js-simple-modal-without-hash").forEach(modal => {
      modalsArr.push(new SimpleModal(modal, true, false));
    });

    return modalsArr;
  }
}

export default ModalControler;
