class FormLabels {
  private activeClassName = "active";
  private focusClassName = "focus";

  constructor(public els: NodeListOf<HTMLInputElement | HTMLTextAreaElement>) {
    this.initEventsListeners();
  }

  private initEventsListeners(): void {
    this.els.forEach(input => {
      input.addEventListener("focus", this.onElementFocus.bind(this));
      input.addEventListener("blur", this.onElementBlur.bind(this));
      input.addEventListener("focusin", (e: Event) => {
        this.onElementFocusState(e, "in");
      });
      input.addEventListener("focusout", (e: Event) => {
        this.onElementFocusState(e, "out");
      });
    });
  }

  private onElementFocus(e: Event): void {
    const target = e.target as HTMLInputElement;
    target.closest(".form__control").classList.add(this.activeClassName);
  }

  private onElementBlur(e: Event): void {
    const target = e.target as HTMLInputElement;
    if (!target.value) {
      target.closest(".form__control").classList.remove(this.activeClassName);
    }
  }

  private onElementFocusState(e: Event, state: string): void {
    const target = e.target as HTMLInputElement;
    if (state === "in") {
      target.closest(".form__control").classList.add(this.focusClassName);
    } else {
      target.closest(".form__control").classList.remove(this.focusClassName);
    }
  }
}

export default FormLabels;
