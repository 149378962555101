/* global IntersectionObserver */

export default (
  el: Element,
  cb: (el?: Element) => void,
  options?: IntersectionObserverInit
): void => {
  const Observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const { target } = entry;
        cb(target);
        Observer.unobserve(target);
      }
    });
  }, options);

  Observer.observe(el);
};
