import Popup from "./Popup";
import Controler from "../base/Controler";

class PopupControler extends Controler {
  private popups: Popup[] = [];

  constructor() {
    super();

    this.mountPopups();
  }

  private mountPopups(): void {
    this.selectElements(".js-popup").forEach(popup => {
      this.popups.push(new Popup(popup));
    });
  }
}

export default PopupControler;
