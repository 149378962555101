import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

abstract class Modal {
  private closeBtnSelector = "js-close-modal";
  private activeModalClass = "modal--show";
  public openBtn: NodeListOf<HTMLElement>;
  public closeBtn: NodeListOf<HTMLElement>;
  private open = false;

  constructor(
    public modal: HTMLElement,
    public lockBody = true,
    public hasHash = true
  ) {
    this.openBtn = document.querySelectorAll(`[data-modal="${this.modal.id}"]`);
    if (this.modal && this.openBtn.length) {
      this.closeBtn = this.modal.querySelectorAll(`.${this.closeBtnSelector}`);
      this.init();
    }
  }

  abstract cbOnModalOpen(): void;

  abstract cbOnModalClose(): void;

  get openStatus(): boolean {
    return this.open;
  }

  public openModal(e?: Event): boolean {
    if (e) e.preventDefault();

    if (!this.open) {
      if (this.lockBody) disableBodyScroll(this.modal);
      this.modal.classList.add(this.activeModalClass);
      this.open = true;
      if (this.hasHash) this.updateHashLocation(this.modal.id);
      this.cbOnModalOpen();
    } else {
      this.closeModal();
    }

    return false;
  }

  public closeModal(e?: Event): boolean {
    if (e) e.preventDefault();
    this.modal.classList.remove(this.activeModalClass);
    this.open = false;
    if (this.hasHash) this.removeHash();
    this.cbOnModalClose();

    if (this.lockBody) enableBodyScroll(this.modal);

    return false;
  }

  private init(): void {
    this.openBtn.forEach(btn => {
      btn.addEventListener("click", this.openModal.bind(this));
    });
    this.closeBtn.forEach(btn => {
      btn.addEventListener("click", this.closeModal.bind(this));
    });
    document.addEventListener("keyup", this.keyEvents.bind(this));

    if (this.hasHash) this.checkforUrlHash();
  }

  private keyEvents(e: KeyboardEvent): void {
    if (e.key === "Escape" && this.closeBtn) {
      this.closeModal();
    }
  }

  private updateHashLocation(hash: string): void {
    window.location.hash = hash;
  }

  private removeHash(): void {
    const noHashURL = window.location.href.replace(/#.*$/, "");
    window.history.replaceState("", document.title, noHashURL);
  }

  private checkforUrlHash(): void {
    const { hash } = window.location;

    if (hash.substring(1) === this.modal.id) {
      this.openModal();
    }
  }
}

export default Modal;
