import autosize from "autosize";
import Controler from "../base/Controler";
import FormLabels from "./FormLabels";
import SimpleForm from "./SimpleForm";
import FileUpload from "./fileUpload";

class FormControler extends Controler {
  constructor() {
    super();

    this.init();
  }

  init(): void {
    this.initLabels();
    this.simpleForm();
    this.initTextareaAutosize();
    this.uploadFiles();
  }

  private initLabels(): FormLabels {
    const els: NodeListOf<
      HTMLInputElement | HTMLTextAreaElement
    > = document.querySelectorAll(
      ".form__textarea, .form__input, .form__select"
    );

    return new FormLabels(els);
  }

  private simpleForm(): SimpleForm[] {
    const formArr: SimpleForm[] = [];
    const formEls: NodeListOf<HTMLFormElement> = document.querySelectorAll(
      ".js-simple-form"
    );

    formEls.forEach(formEl => {
      formArr.push(new SimpleForm(formEl));
    });

    return formArr;
  }

  private uploadFiles(): void {
    this.selectElements(".js-file-upload").forEach(el => {
      new FileUpload(el);
    });
  }

  private initTextareaAutosize(): void {
    const els: NodeListOf<HTMLTextAreaElement> = document.querySelectorAll(
      ".form__textarea"
    );
    autosize(els);
  }
}

export default FormControler;
