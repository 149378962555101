import Controler from "../base/Controler";
import MobileMenu from "./MobileMenu";

class HeaderControler extends Controler {
  constructor() {
    super();
    const mobileMenu = new MobileMenu();
  }
}

export default HeaderControler;
