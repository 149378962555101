import Form, { FormResponseData } from "./Form";

class SimpleForm extends Form {
  onSuccessCb(resp: FormResponseData): void {
    this.form.classList.add("isSubmited");
    // this.form.reset();
    // const uploadList = this.form.querySelector(".js-files-list");
    // if (uploadList) uploadList.innerHTML = "";
  }

  showSuccessMessage(): void {}

  showErrorLable(el: HTMLElement, message: string): void {
    const errorTemplate = `
    <div class="form__error-message">
      <p>${message}</p>
    </div>
    `;

    el.insertAdjacentHTML("afterend", errorTemplate);
  }

  removeErrorLable(el: HTMLElement): void {
    const errorMessage: Element = el.nextElementSibling;
    if (!errorMessage) return;

    if (errorMessage.classList.contains("form__error-message")) {
      const parent = errorMessage.parentElement;
      parent.removeChild(errorMessage);
    }
  }

  showGlobalError(message: string): void {
    if (message) {
      this.form.insertAdjacentHTML(
        "beforeend",
        `
      <div id="form-error" class="text-block text-red mt-2 text-center">
         <p>${message}</p>
      </div>
      `
      );
    }
  }

  removeGlobalError(): void {
    const notes: NodeListOf<Element> = this.form.querySelectorAll(
      "#form-error"
    );
    notes.forEach(note => {
      const parent = note.parentElement;
      parent.removeChild(note);
    });
  }
}

export default SimpleForm;
