import SimpleParallax from "simple-parallax-js";

export default (): void => {
  const images = document.querySelectorAll(".js-parallax img");
  images.forEach(img => {
    new SimpleParallax(img, {
      scale: 1.22,
      delay: 0.6,
      transition: "cubic-bezier(0,0,0,1)"
    });
  });
};
