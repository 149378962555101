import inView from "../../addons/inView";

export default (): void => {
  const content = document.querySelectorAll(
    ".js-reveal-text-block > *, .js-reveal-text-block li"
  );

  content.forEach(hr =>
    inView(
      hr,
      el => {
        el.classList.add("reveal");
      },
      { rootMargin: window.innerWidth > 767 ? "-40px" : "0px" }
    )
  );
};
