import inView from "../../addons/inView";

export default (): void => {
  const horizontalLines = document.querySelectorAll(".js-reveal-line");
  horizontalLines.forEach(hr =>
    inView(
      hr,
      el => {
        el.classList.add("reveal");
      },
      { rootMargin: window.innerWidth > 767 ? "-80px" : "0px" }
    )
  );
};
