import Controler from "../base/Controler";
import Map from "./Map";

class MapControler extends Controler {
  constructor() {
    super();
    this.initMap();
  }

  private initMap(): void {
    this.selectElements(".js-map").forEach(el => {
      const map = new Map(el);
    });
  }
}

export default MapControler;
