import revealLine from "./revealLine";
import revealContent from "./revealContent";
import revealTextBlock from "./revealTextBlock";
import imageParallax from "./ImageParallax";

export default (): void => {
  revealLine();
  revealContent();
  revealTextBlock();
  imageParallax();
};
