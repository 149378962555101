import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

class MobileMenu {
  private header: HTMLElement;
  private burgerBtn: NodeListOf<HTMLElement>;
  private body: HTMLBodyElement = document.querySelector("body");
  private isMenuOpen = false;
  private navigation: HTMLElement;

  constructor(selector = ".header") {
    this.header = document.querySelector(selector);
    if (this.header) {
      this.init();
    }
  }

  private init(): void {
    this.setupNavigation();
  }

  private setupNavigation(): void {
    this.burgerBtn = this.header.querySelectorAll(".js-toggle-nav");
    this.navigation = this.header.querySelector(".header__nav");

    if (this.burgerBtn && this.navigation) {
      this.burgerBtn.forEach((button: HTMLElement): void => {
        button.addEventListener("click", this.toggleNavMenu.bind(this));
      });
    }
  }

  private toggleNavMenu(e: Event): void {
    e.preventDefault();
    this.body.classList.toggle("menu-open");
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      disableBodyScroll(this.navigation);
    } else {
      enableBodyScroll(this.navigation);
    }
  }
}

export default MobileMenu;
