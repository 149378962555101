/* eslint-disable @typescript-eslint/no-unused-vars */

import "../scss/style.scss";
import { nodeListForEach } from "./addons/polyfills";
import appLoaded from "./addons/appLoaded";
import vhSetCustomUnit from "./addons/vhUnitsCorrection";
import Works from "./modules/works";
import animations from "./modules/animations";
import stickyHeader from "./modules/header/stickyHeader";
import showHeader from "./modules/header/showHeader";
import ShowMoreControle from "./modules/showMore/ShowMoreControler";
import FormControler from "./modules/form/FormControler";
import ScrollToControler from "./modules/scrollTo/ScrollToControler";
import PopupControler from "./modules/popup/PopupControler";
import HeaderControler from "./modules/header/HeaderControler";
import ModalControler from "./modules/modal/ModalControler";
import MapControler from "./modules/map/MapControler";

nodeListForEach();

const inits = (): void => {
  const works = new Works();
  const showMoreControle = new ShowMoreControle();
  const formControler = new FormControler();
  const scrollToControler = new ScrollToControler();
  const popupControler = new PopupControler();
  const headerControler = new HeaderControler();
  const modalControler = new ModalControler();
  const mapControler = new MapControler();
  animations();
};

const onScroll = (e: Event): void => {
  stickyHeader();
};

const onResize = (e: Event): void => {
  onScroll(e);
  vhSetCustomUnit();
};

const onLoad = (e: Event): void => {
  inits();
  onResize(e);
  appLoaded();
  showHeader();
};

window.addEventListener("load", onLoad);
window.addEventListener("resize", onResize);
window.addEventListener("scroll", onScroll);
