/* global DataTransfer Event  */
import { IObject } from "../Interfaces";

class FileUpload {
  input: HTMLInputElement;
  dropzone: HTMLElement;
  itemsList: HTMLElement;
  files: File[] = [];

  constructor(public el: Element) {
    if (el) this.init(el);
  }

  init(el: Element): void {
    this.input = el.querySelector('input[type="file"]');
    this.dropzone = el.querySelector(".js-drop-zone");
    this.itemsList = el.querySelector(".js-files-list");
    this.initEvents();
  }

  private initEvents(): void {
    this.input.addEventListener("change", this.onChange.bind(this));

    if (this.dropzone) {
      this.dropzone.addEventListener("drop", this.onDrop.bind(this));
      this.dropzone.addEventListener("dragover", (e: Event): void => {
        e.preventDefault();
      });
    }
  }

  private onDrop(e: DragEvent): void {
    e.preventDefault();
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          const file = e.dataTransfer.items[i].getAsFile();
          this.files.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        const file = e.dataTransfer.files[i];
        this.files.push(file);
      }
    }
    this.renderFiles();
  }

  private onChange(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.files = [...Array.from(target.files)];
    this.renderFiles();
  }

  private renderFiles = (): void => {
    this.itemsList.innerHTML = "";

    this.files.forEach((file, index) => {
      const fileListItem = document.createElement("DIV");
      fileListItem.insertAdjacentElement("beforeend", this.fileInfo(file));
      fileListItem.insertAdjacentElement(
        "beforeend",
        this.removeBtn(() => {
          this.files.splice(index, 1);
          this.updateInput();
        })
      );

      this.itemsList.insertAdjacentElement("beforeend", fileListItem);
    });
  };

  private updateInput = (): void => {
    const list = new DataTransfer();
    this.files.forEach(file => {
      list.items.add(file);
    });
    this.input.files = list.files;

    const event = new Event("change");
    this.input.dispatchEvent(event);
  };

  private size = (fileSize: number): string => {
    const totalSizeKB = fileSize / 1024 ** 1;
    const totalSizeMB = fileSize / 1024 ** 2;
    if (totalSizeKB > 500) return `${totalSizeMB.toFixed(1)} MB`;
    return `${totalSizeKB.toFixed(0)} KB`;
  };

  private fileInfo = (file: IObject): HTMLElement => {
    const el = document.createElement("SPAN");
    el.innerHTML = `${file.name} <span class="file-size">${this.size(
      file.size
    )}</span>`;

    return el;
  };

  private removeBtn = (onClick: () => void): HTMLButtonElement => {
    const button = document.createElement("button");
    button.type = "button";
    button.classList.add("file-upload__remove");
    button.addEventListener("click", (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    });
    return button;
  };
}

export default FileUpload;

// const removeBtn = (onClick: () => void): HTMLButtonElement => {
//   const button = document.createElement("button");
//   button.type = "button";
//   button.classList.add("file-upload__remove");
//   button.addEventListener("click", (e: Event) => {
//     e.preventDefault();
//     e.stopPropagation();
//     onClick();
//   });
//   return button;
// };

// const size = (fileSize: number) => {
//   const totalSizeKB = fileSize / 1024 ** 1;
//   const totalSizeMB = fileSize / 1024 ** 2;
//   if (totalSizeKB > 500) return `${totalSizeMB.toFixed(1)} MB`;
//   return `${totalSizeKB.toFixed(0)} KB`;
// };

// const fileInfo = (file: IObject): HTMLElement => {
//   const el = document.createElement("SPAN");
//   el.innerHTML = `${file.name} <span class="file-size">${size(
//     file.size
//   )}</span>`;

//   return el;
// };

// const fileUpload = (el: HTMLElement): void => {
//   const input: HTMLInputElement = el.querySelector('input[type="file"]');
//   const dropzone: HTMLElement = el.querySelector(".js-drop-zone");
//   const itemsList: HTMLElement = el.querySelector(".js-files-list");
//   const files: File[] = [];

//   const updateInput = (): void => {
//     console.log(files);
//     const list = new DataTransfer();
//     files.forEach(file => {
//       list.items.add(file);
//     });
//     input.files = list.files;

//     const event = new Event("change");
//     input.dispatchEvent(event);
//   };

//   const renderFiles = () => {
//     itemsList.innerHTML = "";
//     files.forEach((file, index) => {
//       const fileListItem = document.createElement("DIV");
//       fileListItem.insertAdjacentElement("beforeend", fileInfo(file));
//       fileListItem.insertAdjacentElement(
//         "beforeend",
//         removeBtn(() => {
//           files.splice(index, 1);
//           updateInput();
//         })
//       );

//       itemsList.insertAdjacentElement("beforeend", fileListItem);
//     });
//   };

//   if (dropzone) {
//     dropzone.addEventListener("drop", (e: DragEvent) => {
//       e.preventDefault();

//       if (e.dataTransfer.items) {
//         // Use DataTransferItemList interface to access the file(s)
//         for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
//           // If dropped items aren't files, reject them
//           if (e.dataTransfer.items[i].kind === "file") {
//             const file = e.dataTransfer.items[i].getAsFile();
//             files.push(file);
//           }
//         }
//       } else {
//         // Use DataTransfer interface to access the file(s)
//         for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
//           const file = e.dataTransfer.files[i];
//           files.push(file);
//         }
//       }

//       renderFiles();
//     });

//     dropzone.addEventListener("dragover", (e: Event) => {
//       console.log("File(s) in drop zone");
//       // Prevent default behavior (Prevent file from being opened)
//       e.preventDefault();
//     });
//   }

//   input.addEventListener("change", (e: Event): void => {
//     console.log(input.files);
//     for (let i = 0; i < input.files.length; i += 1) {
//       files.push(input.files[i]);
//     }
//     renderFiles();
//     console.log(input.files);
//     console.log(files);
//   });
// };

// export default fileUpload;
