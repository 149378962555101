/* global Image, IntersectionObserver */

class Filter {
  private el: HTMLElement;
  private items: NodeListOf<HTMLElement>;
  private image: HTMLElement;

  constructor() {
    this.el = document.querySelector(".js-works");

    if (this.el) this.init();
  }

  private init(): void {
    this.items = this.el.querySelectorAll(".js-works-item");
    this.image = this.el.querySelector(".js-works-image");
    this.setupEvents();
    this.initImagePreloader();
  }

  private setupEvents(): void {
    if (this.items && this.items.length) {
      this.items.forEach((item, index) => {
        item.addEventListener("mouseenter", this.onMouseEnter.bind(this));
        item.dataset.index = index.toString();
      });
    }
  }

  private onMouseEnter(e: Event): void {
    const target = e.target as HTMLButtonElement;
    const { imageSrc, index } = target.dataset;
    this.image.style.backgroundImage = `url(${imageSrc})`;

    // calculate image translate offset
    const elHeight = this.el.getBoundingClientRect().height;
    const imgHeight = this.image.getBoundingClientRect().height;
    // const offset =
    //   elHeight > imgHeight ? (elHeight - imgHeight) / this.items.length : 0;
    // const calcFullOffset =
    //   offset * (parseInt(index, 10) + parseInt(index, 10) / this.items.length);

    // this.image.style.transform = `translateY(${calcFullOffset}px)`;
  }

  private preloadImages(): void {
    this.items.forEach(item => {
      const { imageSrc } = item.dataset;
      const image = new Image();
      image.src = imageSrc;
    });
  }

  private initImagePreloader(): void {
    if ("IntersectionObserver" in window) {
      const worksObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const works = entry.target;
            this.preloadImages();
            worksObserver.unobserve(works);
          }
        });
      });
      worksObserver.observe(this.el);
    }
  }
}

export default Filter;
