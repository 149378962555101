import ShowMore from "./ShowMore";

class ShowMoreControler {
  private showMoreInstances: ShowMore[] = [];

  constructor() {
    this.init();
  }

  init(): void {
    const nodes: NodeListOf<HTMLElement> = document.querySelectorAll(
      ".js-show-more"
    );

    nodes.forEach(node => {
      const showMore = new ShowMore(node);
      this.showMoreInstances.push(showMore);
    });
  }
}

export default ShowMoreControler;
