let thresholdPixels = 0;
let previosY = window.pageYOffset;
const scrollUpThresholdPixels = 20;

const scrollDown = (y: number): boolean => y - previosY > 0;

export default (): void => {
  const y = window.pageYOffset;
  if (y > 0) {
    document.body.classList.add("scrolled");
  } else {
    document.body.classList.remove("scrolled");
  }

  if (y >= window.innerHeight * 0.5 && scrollDown(y)) {
    document.body.classList.add("hide-menu");
    thresholdPixels = 0;
  }

  if (!scrollDown(y)) {
    thresholdPixels += 1;
    if (
      thresholdPixels >= scrollUpThresholdPixels ||
      y < window.innerHeight * 0.3
    )
      document.body.classList.remove("hide-menu");
  }

  previosY = y;
};
